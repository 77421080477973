<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data') }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-'+RESOURCES_ELEMENT_NAME+'-list'}"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list') }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other') }}
      </div>
    </b-alert>

    <template v-if="elementData">
      <element-edit-tab
        :new-element="newElement"
        :element-data="elementData"
        :department-list="departmentList"
        :city-list="cityList"
        :city-contact-list="cityContactList"
        :city-headquarter-list="cityHeadquarterList"
        :element-logo="logo"
        :is-modal="false"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        :module-name="RESOURCES_ELEMENT_NAME"
        :contact-type-list="contactTypeList"
        :supplier-type-list="supplierTypeList"
        class="mt-2 pt-75"
      />
    </template>

  </div>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import elementStoreModule from '../storeModule'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      TYPE: '',
      newElement: true,
      departmentList: [],
      cityList: [],
      cityContactList: [],
      cityHeadquarterList: [],
      contactTypeList: [],
      supplierTypeList: [],
      elementData: {},
      logo: '',
    }
  },
  async mounted() {
    this.TYPE = router.currentRoute.params.type
    this.RESOURCES_ELEMENT_NAME = router.currentRoute.params.resource_name
    this.ELEMENT_APP_STORE_MODULE_NAME = `apps-comp-ed-${this.$t(`${this.RESOURCES_ELEMENT_NAME}.module_name`)}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)

    await this.fetchDepartments()
    await this.fetchContactTypes()
    await this.fetchSupplierTypes()
    if (router.currentRoute.params.id !== undefined) {
      await this.fetchElement()
      this.cityList = await this.fetchCities(this.elementData.department_id)
    } else {
      this.elementData = {
        status: 1,
        guardians: [],
        contacts: [],
        headquarters: [],
        activation_date: '',
        inactivation_date: '',
        contract_end_date: '',
        company_type_key: this.TYPE,
      }
    }

    this.$root.$on('fetch-cities', async departmentId => {
      this.cityContactList = await this.fetchCities(departmentId)
    })
    this.$root.$on('fetch-cities-headquarter', async departmentId => {
      this.cityHeadquarterList = await this.fetchCities(departmentId)
    })
    this.$root.$on('fetch-cities-company', async departmentId => {
      this.cityList = await this.fetchCities(departmentId)
    })
    this.$root.$on('fetch-element-nit', nit => {
      this.fetchElementNit(nit)
    })
  },
  beforeDestroy() {
    this.$root.$off('fetch-cities')
    this.$root.$off('fetch-cities-headquarter')
    this.$root.$off('fetch-cities-company')
    this.$root.$off('fetch-element-nit')
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchDepartments() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchDepartments`)
        .then(response => {
          this.departmentList = response.data.items
          this.departmentList.unshift({ id: null, value: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchContactTypes() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchContactTypes`, { })
        .then(response => {
          this.contactTypeList = response.data.items
          this.contactTypeList.unshift({ id: null, value: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.contactTypeList = []
          }
        })
    },
    fetchSupplierTypes() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchSupplierTypes`, { })
        .then(response => {
          this.supplierTypeList = response.data.items
          this.supplierTypeList.unshift({ id: null, value: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.supplier = []
          }
        })
    },
    async fetchCities(department_id) {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCities`, { parent: department_id })
        .then(response => {
          const cityList = response.data.items
          cityList.unshift({ id: null, value: this.$t('list.defaultOption') })
          return cityList
        })
        .catch(error => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    fetchElement() {
      this.newElement = false
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.id })
        .then(response => {
          this.elementData = response.data
          if (this.elementData.logo_id) {
            this.fetchLogo(this.elementData.logo_id)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })
    },
    fetchLogo(logo_id) {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchImage`, { id: logo_id })
        .then(response => {
          this.logo = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.logo)
            logo = undefined
          }
        })
    },
    save(elementData) {
      const method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/add_element' : '/save_element')
      const action = this.newElement ? 'creation' : 'update'
      const _self = this
      store
        .dispatch(method, { data: elementData })
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
            },
          })
          _self.$router.push({ name: `apps-${_self.RESOURCES_ELEMENT_NAME}-list` })
        })
        .catch(error => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
              icon: 'SaveIcon',
              variant: 'danger',
              text: `${_self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`)}\n${error.response.data.message}`,
            },
          })
        })
    },
    async fetchElementNit(nit) {
      // this.newElement = false
      console.info('entre')
      const action = 'validate'
      const _self = this
      if (this.elementData.nit != undefined) {
        return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element_nit`, {
          queryParams: {
            nit,
            companyType: this.TYPE,
          },
        })
          .then(response => {
            _self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
                icon: 'SaveIcon',
                variant: 'danger',
                text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
              },
            })
            this.elementData.nit = undefined
          })
      }
    },
  },

}
</script>

<style>

</style>
